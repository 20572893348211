import React from 'react';
import { FormattedMessage } from 'react-intl';

const Faq = () => {
  const items = [
    { title: 'FAQ_1', description: 'FAQ_1_DESC' },
    { title: 'FAQ_2', description: 'FAQ_2_DESC' },
    { title: 'FAQ_3', description: 'FAQ_3_DESC' },
    { title: 'FAQ_4', description: 'FAQ_4_DESC' },
  ];

  return (
    <React.Fragment>
      <h2 className='bold'><FormattedMessage id='FAQ' /></h2>
      <div className='text-container'>
      {items.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <p className='m sapphire bold'><FormattedMessage id={item.title} /></p>
              <p className='m sapphire'><FormattedMessage id={item.description} /></p>
            </React.Fragment>
          );
      })}
      </div>
    </React.Fragment>
  );
};

export default Faq;
