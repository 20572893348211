import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const Validation = () => {
  const currentLanguage = useSelector((state) => state.language.language);

  const items = [
    { title: 'VALID_TRAVEL_DOCUMENT_REQUIRED' },
    { title: 'VALIDATION_DESC' },
    { title: 'APPROACH_CARD_TO_VALIDATOR' },
    { title: 'VALIDATION_UPON_TRANSFER' },
    { title: 'VALIDATION_ALL_DOCUMENTS' },
    { title: 'SUCCESSFUL_VALIDATION' },
    { title: 'TRAVEL_INSURANCE' },
  ];

  return (
    <React.Fragment>
      <h2 className='bold'>
        <FormattedMessage id='HOW_TO_VALIDATE_LONG' />
      </h2>
      <div className='text-container custom'>
        {items.map((item, index) => {
          return (
            <div key={index}>
              <p className='m sapphire'>
                <FormattedMessage id={item.title} />
              </p>
            </div>
          );
        })}
        <div className='image-container'>
          <img className='content-image' src={`/validation_1_${currentLanguage}.png`} alt='Validation 1' />
          <img className='content-image' src={`/validation_2_${currentLanguage}.png`} alt='Validation 2' />
        </div>
        <p className='m sapphire'>
          <FormattedMessage id='FAILED_VALIDATION' />
        </p>
        <div className='image-container'>
          <img className='content-image' src={`/validation_3_${currentLanguage}.png`} alt='Validation 3' />
          <img className='content-image' src={`/validation_4_${currentLanguage}.png`} alt='Validation 4' />
          <img className='content-image' src={`/validation_5_${currentLanguage}.png`} alt='Validation 5' />
        </div>
        <p className='m sapphire'>
          <FormattedMessage id='FAILED_VALIDATION_MULTIPLE_CARDS' />
        </p>
      </div>
      <h2 className='bold'>
        <FormattedMessage id='INSPECTION' />
      </h2>
      <div className='text-container'>
        <p className='m sapphire'>
          <FormattedMessage id='INSPECTION_1' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='INSPECTION_2' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='INSPECTION_3' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='INSPECTION_4' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='INSPECTION_5' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='INSPECTION_6' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='INSPECTION_7' />{' '}
          <a
            href={`https://www.sofiatraffic.bg/${currentLanguage}/transport/tarifa-i-prevozni-dokumenti/705/kontrol-po-spazvane-na-pravilata`}
            target='_blank'
            rel='noreferrer'
            className='bold link'>
            <FormattedMessage id='HERE' />
          </a>
          .
        </p>
      </div>
    </React.Fragment>
  );
};

export default Validation;
