import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

const When = () => {
  return (
    <React.Fragment>
      <h2 className='bold'>
        <FormattedMessage id='WHEN_AND_HOW_LONG' />
      </h2>
      <div className='text-container'>
        <p className='m lochmara'>
          <FormattedMessage id='COMMENCEMENT' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='STAGES' />
          &nbsp;
          <FormattedMessage id='MORE_ABOUT_REPLACEMENT' />
          <NavLink to={'/about/traveling'} className='bold link'>
            &nbsp;
            <FormattedMessage id='HERE' />.
          </NavLink>
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='REPLACE_OLD_CARD' />
        </p>
      </div>
    </React.Fragment>
  );
};

export default When;
