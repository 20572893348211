import React, { useEffect } from 'react';
import './Home.css';
import { useSelector } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

const Home = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const cards = useSelector((state) => state.cards.cards);

  const largeCards = cards.filter((card) => {
    return card.type === 'large';
  });

  const smallCards = cards.filter((card) => {
    return card.type === 'small';
  });

  const groupedCards = smallCards.reduce((rows, key, index) => {
    return (index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows;
  }, []);

  let className = 'sliding-icon';

  window.onscroll = () => {
    if (window.pageYOffset > 200) {
      console.log('200px');
    }
  };

  return (
    <div className='viewport'>
      <div className='content'>
        <h1>
          <FormattedMessage id='TITLE' />
        </h1>
        <p className='m lochmara' style={{ paddingTop: '30px' }}>
          <FormattedMessage id='DESCRIPTION' />
        </p>
        <div className='h-content'>
          <div className='cards-wrapper'>
            {largeCards.map((card, index) => {
              return (
                <Link to={'about/' + card.url} key={index}>
                  <div className='card large' style={{ backgroundImage: `url(/home/${card.image}.png)` }}>
                    <div className='card-image'>
                      <img className={className} src={`/home/${card.image}.svg`} alt={card.image} />
                    </div>
                    <div className='card-content'>
                      <h2>
                        <FormattedMessage id={card.title} />
                      </h2>
                      <p className='s lochmara'>
                        <FormattedMessage id='SEE_MORE' />
                      </p>
                    </div>
                  </div>
                </Link>
              );
            })}
            {groupedCards.map((group, index) => {
              return (
                <div className='cards-row' key={index}>
                  {group.map((card) => {
                    return (
                      <Link to={'about/' + card.url} key={card.url}>
                        <div className='card small'>
                          <div className='card-image' style={{ backgroundImage: `url(/home/${card.image}.png)` }}>
                            <img className='card-icon' src={`/home/${card.image}.svg`} alt={card.image} />
                          </div>
                          <div className='card-content'>
                            <h2>
                              <FormattedMessage id={card.title} />
                            </h2>
                            <p className='s lochmara'>
                              <FormattedMessage id='SEE_MORE' />
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <a href={`https://webportal.sofiatraffic.bg/`} target='_blank' rel='noreferrer'>
            <div className='baner'>
              <img src='baner.jpg' alt='Baner' />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
