import React from 'react';
import { FormattedMessage } from 'react-intl';

const Topup = () => {
  return (
    <React.Fragment>
      <h2 className='bold'>
        <FormattedMessage id='TOPUP_BENEFITS' />
      </h2>
      <div className='text-container'>
        <p className='m sapphire bold'>
          <FormattedMessage id='MORE_ABOUT_WEBPORTAL' />
          <a href={`https://webportal.sofiatraffic.bg/`} target='_blank' rel='noreferrer' className='bold link'>
            <FormattedMessage id='THE_WEB_PORTAL' /> (webportal.sofiatraffic.bg)
          </a>
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='WEBPORTAL_OPTIONS' />
        </p>
        <ul className='about-list collapsed'>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='ONLINE_ISSUE' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='TOPUP_ONLINE' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='CHECK_HISTORY' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='CHECK_PURCHASES' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='WEBPORTAL_NEWS' />
            </p>
          </li>
          <li>
            <p className='m sapphire' style={{ paddingBottom: '30px' }}>
              <FormattedMessage id='WEBPORTAL_SURVEYS' />
            </p>
          </li>
        </ul>
        <div className='separator'></div>
        <p className='m sapphire bold'>
          <FormattedMessage id='HOW_TO_REGISTER' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='REGISTRATION_STEPS' />
        </p>
        <ol className='about-list collapsed'>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='ENTER_EMAIL_AND_PASSWORD' /> <FormattedMessage id='EMAIL_AND_PASSWORD_DESC' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='VERIFY_EMAIL' />
              <a href={`https://webportal.sofiatraffic.bg/`} target='_blank' rel='noreferrer' className='bold link'>
                <FormattedMessage id='THE_WEB_PORTAL' />
              </a>
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='ENTER_PHONE_NUMBER' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='VERIFY_PHONE_NUMBER' />
            </p>
          </li>
          <li>
            <p className='m sapphire' style={{ paddingBottom: '30px' }}>
              <FormattedMessage id='ENTER_VERIFICATION_PIN' />
            </p>
          </li>
        </ol>
        <div className='separator'></div>
        <p className='m sapphire bold'>
          <FormattedMessage id='ASSOCIATE_CARDS' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='ASSOCIATE_CARDS_DESC' />
        </p>
        <div className='separator'></div>
        <p className='m sapphire bold'>
          <FormattedMessage id='WEBPORTAL_TOPUP' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='WEBPORTAL_TOPUP_DESC' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='WEBPORTAL_TOPUP_STEPS' />
        </p>
        <ol className='about-list collapsed'>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='CHOOSE_DOCUMENT' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='ADD_TO_CARD' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='CONFIGURATE_DOCUMENT' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='CONFIRM_PURCHASE' />
            </p>
          </li>
          <li>
            <p className='m sapphire' style={{ paddingBottom: '30px' }}>
              <FormattedMessage id='PURCHASE_WITH_CREDIT_OR_DEBIT_CARD' />
            </p>
          </li>
        </ol>
        <p className='m sapphire'>
          <FormattedMessage id='PURCHASE_CONFIRMATION_EMAIL' />
        </p>
        <div className='separator'></div>
        <p className='m sapphire bold'>
          <FormattedMessage id='WHERE_TO_RECEIVE' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='WHERE_TO_RECEIVE_DESC' />
        </p>
        <div className='separator'></div>
        <p className='m sapphire bold'>
          <FormattedMessage id='TOPUP_DESCENDANTS_DOCUMENTS' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='TOPUP_DESCENDANTS_DOCUMENTS_DESC' />
          <a href={`https://webportal.sofiatraffic.bg/`} target='_blank' rel='noreferrer' className='bold link'>
            <FormattedMessage id='THE_WEB_PORTAL' />.
          </a>
        </p>
        <div className='separator'></div>
        <p className='m sapphire bold'>
          <FormattedMessage id='FAQ_5' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='FAQ_5_DESC' />
        </p>
      </div>
    </React.Fragment>
  );
};

export default Topup;
