import React from 'react';
import './App.css';
import { IntlProvider } from 'react-intl';
import Bulgarian from './services/translations/bg.json';
import English from './services/translations/en.json';
import { BrowserRouter as Router } from 'react-router-dom';
import { changeLanguage } from './store/actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import Navigation from './components/Navigation/Navigation';
import Landing from './components/Landing/Landing';
import Routes from './Routes';
import Footer from './components/Footer/Footer';
import ReactGA from 'react-ga';

const languages = {
  en: English,
  bg: Bulgarian,
};

const App = () => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-59842426-4');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const currentLanguage = useSelector((state) => state.language.language);
  const dispatch = useDispatch();

  if (!localStorage.getItem('language') || !Object.keys(languages).includes(localStorage.getItem('language'))) {
    localStorage.setItem('language', 'bg');
  } else {
    dispatch(changeLanguage(localStorage.getItem('language')));
  }

  return (
    <IntlProvider locale={currentLanguage || localStorage.getItem('language')} messages={languages[currentLanguage || localStorage.getItem('language')]}>
      <Router>
        <Navigation />
        <Landing />
        <Routes />
      </Router>
      <Footer />
    </IntlProvider>
  );
};

export default App;
