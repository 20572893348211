import React from 'react';
import { FormattedMessage } from 'react-intl';

const Expiration = () => {
  return (
    <React.Fragment>
      <h2 className='bold'><FormattedMessage id='OLD_CARDS_VALIDITY' /></h2>
      <div className='text-container'>
        <p className='m sapphire'>Предлагаме съдържанието на тази точка да я формулираме съвместно с екипа на ЦГМ , за да посочим коректно информацията по преходния период.</p>
      </div>
    </React.Fragment>
  );
};

export default Expiration;
