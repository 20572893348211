import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const Surveillance = () => {
  const currentLanguage = useSelector((state) => state.language.language);

  const items = [{ title: 'VIDEO_SURVEILLANCE_DESC' }, { title: 'VIDEO_SURVEILLANCE_DESC_2' }, { title: 'VIDEO_SURVEILLANCE_DESC_3' }];

  return (
    <React.Fragment>
      <h2 className='bold'>
        <FormattedMessage id='VIDEO_SURVEILLANCE_LONG' />
      </h2>
      <div className='text-container'>
        {items.map((item, index) => {
          return (
            <p key={index} className='m sapphire'>
              <FormattedMessage id={item.title} />
            </p>
          );
        })}
        <p className='m sapphire'>
          <FormattedMessage id='MORE_ABOUT_VIDEO_SURVEILLANCE' />{' '}
          <a href={`https://www.sofiatraffic.bg/${currentLanguage}/common/1211/politiki-za-poveritelnost`} target='_blank' rel='noreferrer' className='bold link'>
            <FormattedMessage id='HERE' />
          </a>
          .
        </p>
      </div>
    </React.Fragment>
  );
};

export default Surveillance;
