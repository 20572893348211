import React, { useState } from 'react';
import './Navigation.css';
import { changeLanguage } from '../../store/actions/actions';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Navigation = () => {
  let url = useLocation();
  let [toggled, toggleNavigation] = useState(false);

  const currentLanguage = useSelector((state) => state.language.language);

  const newLanguage = () => {
    if (currentLanguage === 'bg' || localStorage.getItem('language') === 'bg') {
      return 'en';
    } else {
      return 'bg';
    }
  };

  const dispatch = useDispatch();

  return (
    <nav className='main'>
      <div className='logo-container'>
        <a href={`https://www.sofiatraffic.bg/${currentLanguage}`} target='_blank' rel='noreferrer'>
          <img className='logo-desktop' src={`/logo-${currentLanguage}.svg`} alt='Logo UMC' />
        </a>
      </div>
      <div className='language-toggler'>
        <Link to={url} onClick={() => dispatch(changeLanguage(newLanguage()))}>
          {newLanguage()}
        </Link>
      </div>
      <label htmlFor='hamburger-menu' className={`navigation ${toggled ? 'toggled' : ''}`} onClick={() => toggleNavigation(!toggled)}>
        <div className='hamburger-menu'>
          <div className='bar-1'></div>
          <div className='bar-2'></div>
          <div className='bar-3'></div>
        </div>
      </label>
      <input type='checkbox' id='hamburger-menu' checked={toggled} readOnly />
      <div className='nav-items'>
        <Link to='/' className='nav-item' onClick={() => toggleNavigation(!toggled)}>
          <FormattedMessage id='HOME' />
        </Link>
        <a href={`https://www.sofiatraffic.bg/${currentLanguage}/news/p/1`} target='_blank' rel='noreferrer' className='nav-item'>
          <FormattedMessage id='NEWS' />
        </a>
        <a href={`https://www.sofiatraffic.bg/${currentLanguage}/common`} target='_blank' rel='noreferrer' className='nav-item'>
          <FormattedMessage id='MAIN_WEBSITE' />
        </a>
        <Link to={url} className='nav-item language' onClick={() => dispatch(changeLanguage(newLanguage()))}>
          {newLanguage()}
        </Link>
      </div>
    </nav>
  );
};

export default Navigation;
