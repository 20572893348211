import React from 'react';
import { FormattedMessage } from 'react-intl';

const Traveling = () => {
  return (
    <React.Fragment>
      <h2 className='bold'>
        <FormattedMessage id='HOW_TO_TRAVEL_LONG' />
      </h2>
      <div className='text-container'>
        <p className='m sapphire bold'>
          <FormattedMessage id='CONTACTLESS_CARD' />
        </p>
        <img className='traveling-image' src={'/traveling_1.png'} alt='Contactless card' />
        <img className='traveling-image' src={'/traveling_1.jpg'} alt='Contactless card' style={{ marginLeft: '25px' }} />
        <p className='m sapphire'>
          <FormattedMessage id='CONTACTLESS_CARD_DESC' />
        </p>
        <p className='m sapphire bold'>
          <FormattedMessage id='CREDIT_DEBIT_CARDS' />
        </p>
        <img className='traveling-image' src={'/traveling_4.png'} alt='Payment card' />
        <img className='traveling-image' src={'/traveling_2.jpg'} alt='Contactless card' style={{ marginLeft: '25px' }} />
        <p className='m sapphire'>
          <FormattedMessage id='CREDIT_DEBIT_CARDS_DESC' />
        </p>
        <p className='m sapphire bold'>
          <FormattedMessage id='SINGLE_TRIP_CARD' />
        </p>
        <img className='traveling-image' src={'/traveling_3.png'} alt='Payment card' />
        <p className='m sapphire'>
          <FormattedMessage id='SINGLE_TRIP_CARD_DESC' />
        </p>
      </div>
    </React.Fragment>
  );
};

export default Traveling;
