import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const Issue = () => {
  const currentLanguage = useSelector((state) => state.language.language);

  return (
    <React.Fragment>
      <h2 className='bold'>
        <FormattedMessage id='HOW_TO_ISSUE_AND_TOPUP_LONG' />
      </h2>
      <div className='text-container'>
        <p className='m sapphire bold'>
          <FormattedMessage id='THROUGH_ONLINE_WEBPORTAL' />
          <a href={`https://webportal.sofiatraffic.bg/`} target='_blank' rel='noreferrer' className='bold link'>
            <FormattedMessage id='WEB_PORTAL' />
          </a>
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='HOW_TO_ISSUE_AND_TOPUP_OPTIONS' />
        </p>
        <ul className='about-list collapsed'>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='SUBMIT_APPLICATION_ONLINE' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='TOPUP_SMART_CARD' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='VIEW_HISTORY_PURCHASED_DOCUMENTS' />
            </p>
          </li>
          <li>
            <p className='m sapphire' style={{ paddingBottom: '30px' }}>
              <FormattedMessage id='VIEW_HISTORY_SUCCESSFUL_VALIDATIONS' />
            </p>
          </li>
        </ul>
        <div className='separator'></div>
        <p className='m sapphire bold'>
          <FormattedMessage id='UMC_POS' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='HOW_TO_ISSUE_AND_TOPUP_OPTIONS' />
        </p>
        <ul className='about-list collapsed'>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='SUBMIT_APPLICATION_ONLINE' />
            </p>
          </li>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='TOPUP_SMART_CARD' />
            </p>
          </li>
          <li>
            <p className='m sapphire' style={{ paddingBottom: '30px' }}>
              <FormattedMessage id='RECEIVE_CARD_REQUESTED_ONLINE' />
            </p>
          </li>
        </ul>
        <p className='m sapphire'>
          <FormattedMessage id='UMC_POS_ADDRESSES' />{' '}
          <a href={`https://www.sofiatraffic.bg/${currentLanguage}/transport/punktove-za-prodazhba-na-prevozni/p/1`} target='_blank' rel='noreferrer' className='bold link'>
            <FormattedMessage id='HERE' />
          </a>
          .
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='CORPORATE_CLIENTS' />
        </p>
        {/* <div className='separator'></div>
        <p className='m sapphire bold'>
          <FormattedMessage id='PARTNER_POS' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='HOW_TO_ISSUE_AND_TOPUP_OPTIONS' />
        </p>
        <ul className='about-list collapsed'>
          <li>
            <p className='m sapphire' style={{ paddingBottom: '30px' }}>
              <FormattedMessage id='TOPUP_SMART_CARD' />
            </p>
          </li>
        </ul>
        <p className='m sapphire'>
          <FormattedMessage id='PARTNER_POS_ADDRESSES' />{' '}
          <a href={`https://www.sofiatraffic.bg/${currentLanguage}`} className='bold'>
            <FormattedMessage id='HERE' />
          </a>
          .
        </p>
        <div className='separator'></div>
        <p className='m sapphire bold'>
          <FormattedMessage id='TVM' />
        </p>
        <p className='m sapphire'>
          <FormattedMessage id='HOW_TO_ISSUE_AND_TOPUP_OPTIONS' />
        </p>
        <ul className='about-list collapsed'>
          <li>
            <p className='m sapphire'>
              <FormattedMessage id='PURCHASE_TVM' />
            </p>
          </li>
          <li>
            <p className='m sapphire' style={{ paddingBottom: '30px' }}>
              <FormattedMessage id='TOPUP_SMART_CARD' />
            </p>
          </li>
        </ul>
        <p className='m sapphire'>
          <FormattedMessage id='TVM_ADDRESSES' />{' '}
          <a href={`https://www.sofiatraffic.bg/${currentLanguage}/transport/punktove-za-prodazhba-na-prevozni/p/1`} className='bold'>
            <FormattedMessage id='HERE' />
          </a>
          .
        </p> */}
      </div>
    </React.Fragment>
  );
};

export default Issue;
