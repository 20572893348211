import React from 'react';
import { FormattedMessage } from 'react-intl';

const Benefits = () => {
  const items = [
    { title: 'EASY', description: 'EASY_DESC' },
    { title: 'CONVENIENT', description: 'CONVENIENT_DESC' },
    { title: 'FAST', description: 'FAST_DESC' },
    { title: 'PRACTICAL', description: 'PRACTICAL_DESC' },
    { title: 'SECURE', description: 'SECURE_DESC' },
  ];

  return (
    <React.Fragment>
      <h2 className='bold'><FormattedMessage id='BENEFITS_AND_ADVANTAGES_LONG' /></h2>
      <div className='text-container'>
        <p className='m lochmara' style={{ paddingBottom: '30px' }}>
          <FormattedMessage id='BENEFITS_AND_ADVANTAGES_DESC' />
        </p>
        <ul className='about-list'>
          {items.map((item, index) => {
            return (
              <li key={index}>
                <p className='m sapphire'>
                  <span className='bold'>
                    <FormattedMessage id={item.title} />
                    &#58;&nbsp;
                  </span>
                  <FormattedMessage id={item.description} />
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Benefits;
