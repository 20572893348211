import React, { useState } from 'react';
import './Aside.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Aside = () => {
  const items = useSelector((state) => state.cards.cards);
  let { url } = useRouteMatch();

  let [showAside, toggleAside] = useState(false);

  return (
    <React.Fragment>
      <div className='aside-toggler' onClick={() => toggleAside(!showAside)}>
        {showAside ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
      </div>
      <aside className={`${showAside ? 'show-aside' : ''}`}>
        {items.map((item, index) => {
          return (
            <NavLink to={`${url}/${item.url}`} key={index} className='aside-item' activeClassName='active-item' onClick={() => toggleAside(!showAside)}>
              <FormattedMessage id={item.title} />
            </NavLink>
          );
        })}
      </aside>
    </React.Fragment>
  );
};

export default Aside;
