import * as actionTypes from '../actions/actions';

const initialState = {
  language: 'bg',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      localStorage.setItem('language', action.payload);
      return { ...state, language: (state.language = action.payload) };
    default:
      return state;
  }
};

export default reducer;
