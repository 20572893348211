const initialState = {
  cards: [
    { type: 'large', title: 'BENEFITS_AND_ADVANTAGES', image: 'benefits', url: 'benefits-advantages' },
    { type: 'small', title: 'WHEN_AND_HOW', image: 'when', url: 'when-how' },
    { type: 'small', title: 'HOW_TO_TRAVEL', image: 'traveling', url: 'traveling' },
    { type: 'small', title: 'HOW_TO_VALIDATE', image: 'validation', url: 'validation' },
    { type: 'small', title: 'HOW_TO_ISSUE_AND_TOPUP', image: 'issue', url: 'issue-topup' },
    { type: 'small', title: 'TOPUP_BENEFITS', image: 'topup', url: 'webportal' },
    { type: 'small', title: 'VIDEO_SURVEILLANCE', image: 'surveillance', url: 'video-surveillance' },
    { type: 'small', title: 'FAQ', image: 'faq', url: 'faq' },
  ],
};

const reducer = (state = initialState, action) => {
  return state;
};

export default reducer;
