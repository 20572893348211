import React from 'react';
import Particles from 'react-particles-js';
import './Landing.css';

const Landing = () => {
  const particles = () => {
    if (window.innerWidth > 1024) {
      return 75;
    } else {
      return 20;
    }
  };

  return (
    <section className='landing' style={{ backgroundImage: 'url(/landing.jpg)' }}>
      <img className='smart-city-logo' src='/logo.png' alt='Logo Smart city' />
      <Particles
        className='particles'
        params={{
          particles: {
            number: {
              value: particles(),
            },
            size: {
              value: 3,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: 'repulse',
              },
            },
          },
        }}
      />
    </section>
  );
};

export default Landing;
