import React, { useEffect } from 'react';
import './About.css';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import Aside from '../Aside/Aside';
import Benefits from './Benefits';
import When from './When';
import Traveling from './Traveling';
import Validation from './Validation';
import Issue from './Issue';
import Topup from './Topup';
import Expiration from './Expiration';
import Surveillance from './Surveillance';
import Faq from './Faq';

const About = () => {
  let { path } = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className='viewport'>
      <Aside />
      <div className='content'>
        <Switch>
          <Route exact path={path}>
            <h3>Please select a topic.</h3>
          </Route>
          <Route path={`${path}/benefits-advantages`}>
            <Benefits />
          </Route>
          <Route path={`${path}/when-how`}>
            <When />
          </Route>
          <Route path={`${path}/traveling`}>
            <Traveling />
          </Route>
          <Route path={`${path}/validation`}>
            <Validation />
          </Route>
          <Route path={`${path}/issue-topup`}>
            <Issue />
          </Route>
          <Route path={`${path}/webportal`}>
            <Topup />
          </Route>
          <Route path={`${path}/old-cards`}>
            <Expiration />
          </Route>
          <Route path={`${path}/video-surveillance`}>
            <Surveillance />
          </Route>
          <Route path={`${path}/faq`}>
            <Faq />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default About;
