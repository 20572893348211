import React from 'react';
import './Footer.css';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const Footer = () => {
  const currentLanguage = useSelector((state) => state.language.language);
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className='footer-column'>
        <img className='logo-footer' src={`/logo-${currentLanguage}.svg`} alt='Logo UMC' />
      </div>
      <div className='footer-column'>
        <p className='m white'>
          <FormattedMessage id='CONTENT_SUBJECT_TO_COPYRIGHT' />
        </p>
        <p className='m white'>
          Copyright &copy; 1999-{currentYear} <FormattedMessage id='URBAN_MOBILITY_CENTRE' /> &#8210; 2000-{currentYear}{' '}
          <FormattedMessage id='SOFIA_MUNICIPALITY' />
        </p>
      </div>
    </footer>
  );
};

export default Footer;
